import React from "react"

const DashboardRoutes = [{
  path    : '/dashboard',
  element : React.lazy(() => import('routes/dashboard/main')),
  isPrivate: true,
  child   : [{
    path : 'coupon',
    child: [{
      index   : true,
      element : React.lazy(() => import('pages/dashboard/coupon/main'))
    }]
  }, {
    path    : 'profile',
    child   : [{
      index   : true,
      element : React.lazy(() => import('pages/dashboard/profile/index/main'))
    }, {
      path    : 'edit',
      element : React.lazy(() => import('pages/dashboard/profile/edit/main'))
    }, {
      path    : 'password-change',
      element : React.lazy(() => import('pages/dashboard/profile/password-change/main'))
    }, {
      path    : 'delete-account',
      element : React.lazy(() => import('pages/dashboard/profile/delete-account/main'))
    }, {
      path    : 'otp-config',
      element : React.lazy(() => import('pages/dashboard/profile/otp/main'))
    }]
  }, {
    path    : 'project',
    child   : [{
      index   : true,
      element : React.lazy(() => import('pages/dashboard/project/index/main'))
    }, {
      path    : ':projId',
      element : React.lazy(() => import('pages/dashboard/project/id/main'))
    }, {
      path    : ':projId/settings',
      element : React.lazy(() => import('pages/dashboard/project/settings/main'))
    }]
  }, {
    path    : 'process/:procId',
    child   : [
      {
        path    : 'node',
        element : React.lazy(() => import('pages/dashboard/process/id/node-single/main'))
      },
      {
        path    : 'node-single',
        element : React.lazy(() => import('pages/dashboard/process/id/node-single/main'))
      }
    ]
  }, {
    path    : 'module/:moduleId',
    child   : [/*{
      index   : true,
      element : React.lazy(() => import('pages/dashboard/modules/id/main'))
    },*/{
      path    : 'params/cont_to=:cont_to',
      element : React.lazy(() => import('pages/dashboard/modules/params/main'))
    }, {
      path    : 'run',
      element : React.lazy(() => import('pages/dashboard/modules/run/main'))
    }, {
      path    : 'result',
      element : React.lazy(() => import('pages/dashboard/modules/result/main'))
    }]
  }, {
    path : "logic_gate/:logicId",
    element : React.lazy(() => import("pages/dashboard/logic_gate/id"))
  },
  {
    path    : 'module-external/:moduleId',
    child   : [{
      path    : 'params/cont_to=:cont_to',
      element : React.lazy(() => import('pages/dashboard/module-external/params/main'))
    }, {
      path    : 'complete',
      element : React.lazy(() => import('pages/dashboard/module-external/complete/main'))
    }, {
      path    : 'result',
      element : React.lazy(() => import('pages/dashboard/module-external/result/main'))
    }]
  }, {
    path    : 'request',
    child   : [
      {
        index   : true,
        element : React.lazy(() => import('pages/dashboard/request/main'))
      },
      {
        path    : ':reqId/view',
        element : React.lazy(() => import('pages/dashboard/request/estimate-request-view'))
      },
      {
        path    : ':reqId/cro-view',
        element : React.lazy(() => import('pages/dashboard/request/estimate-request-view-cro'))
      },
      {
        path    : ':reqId/product-view',
        element : React.lazy(() => import('pages/dashboard/request/product-request-view'))
      },
      {
        path    : ':reqId/cro-product-view',
        element : React.lazy(() => import('pages/dashboard/request/product-request-view-cro'))
      },
      {
        path: 'estimate',
        element: React.lazy(()=> import('pages/dashboard/request/estimate-request-list'))
      },
      {
        path: 'product',
        element: React.lazy(()=> import('pages/dashboard/request/product-request-list'))
      },
      // {
    //   path    : ':projId/settings',
    //   element : React.lazy(() => import('pages/dashboard/project/settings/main'))
    // }
    ]
  },{
    path    : 'ordering',
    child   : [
      {
        index   : true,
        element : React.lazy(() => import('pages/dashboard/ordering/main'))
      },
      {
        path: 'quotation',
        element: React.lazy(()=> import('pages/dashboard/ordering/quotation-list'))
      },
      {
        path    : 'quotation/:quotationId/view',
        element : React.lazy(() => import('pages/dashboard/ordering/quotation-view'))
      },
      {
        path    : 'quotation/:quotationId/cro-view',
        element : React.lazy(() => import('pages/dashboard/ordering/quotation-view-cro'))
      },
      {
        path: 'order',
        element: React.lazy(()=> import('pages/dashboard/ordering/order-list'))
      },
      {
        path    : 'order/:orderId/view',
        element : React.lazy(() => import('pages/dashboard/ordering/order-view'))
      },
      {
        path    : 'order/:orderId/cro-view',
        element : React.lazy(() => import('pages/dashboard/ordering/order-view-cro'))
      },
    ]
  },
  {
    path: 'history',
    child: [
      {
        index : true,
        element : React.lazy(() => import('pages/dashboard/history/main'))
      },
      {
        path : 'token-history',
        element : React.lazy(() => import('pages/dashboard/history/token-history'))
      },
      {
        path : 'payment-history',
        element : React.lazy(() => import('pages/dashboard/history/payment-history'))
      },
      {
        path : 'ordering-history',
        element : React.lazy(() => import('pages/dashboard/history/ordering-history'))
      },
    ]
  },
  {
    path    : 'simulation-progress',
    element : React.lazy(() => import('pages/dashboard/simulation-progress/main'))
    },
    // {
    //   path: 'history',
    //   element: React.lazy(()=> import('pages/dashboard/history/main'))
    // },
    {
      path: 'notification',
      element: React.lazy(()=> import('pages/dashboard/notification/main'))
    },
    {
      path: 'feedback',
      element: React.lazy(()=> import('pages/dashboard/feedback/main'))
    },
    {
      path: 'ai-bot',
      element: React.lazy(()=> import('pages/dashboard/aichat/main'))
    }
  ]
}]
export default DashboardRoutes
